body {
  background-color: #8B0000;
}

.square-corner {
  border-radius: 0 !important;
}

.row {
  --bs-gutter-x: 0 !important;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}